import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { RegisterComponent } from './register/register.component';
import { PlatformComponent } from './platform/platform.component';
import { PageNotFoundComponent } from './page-not-found.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';

const routes: Routes = [
  // Default: Send them to Register route
  {
    path: '',
    redirectTo: '/platform',
    pathMatch: 'full'
  },

  {
    path: 'platform',
    component: PlatformComponent
    // canActivate: [ CompanyAuthGuard ]
  },

  // // Step 1: Register
  // {
  //   path: 'register',
  //   component: RegisterComponent
  //   // canActivate: [ CompanyAuthGuard ]
  // },

  {
    path: 'privacy',
    component: PrivacyPolicyComponent
  },

  {
    path: '**',
    redirectTo: '/notfound'
  },
  {
    path: 'notfound',
    component: PageNotFoundComponent
  }

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
