<div class="container">
  <div class="row">
    <div class="col-sm">
      <div class="jumbotron">
        <h1 class="display-4">Let's Get Started</h1>
        <p class="lead">Enter your email to register and opt-in</p>
        <hr class="my-4">

        <div class="alert alert-info">
          <h2>Registration Received</h2>
          inviteResponse.message
        </div>

<!--         <form (ngSubmit)="register()" [formGroup]="form" *ngIf="!inviteResponse || inviteResponse.error">
          <div class="form-group">
            <label for="emailAddressField">Email address</label>

            <input
              type="email"
              class="form-control"
              id="emailAddressField"
              aria-describedby="emailHelp"
              placeholder="Enter email"
              formControlName="email">

            <small id="emailHelp" class="form-text text-muted">
              We'll never share your email with anyone else.
            </small>
          </div>

          <button type="submit" class="btn btn-primary" [disabled]="!form.valid || !company">Next</button>

          <div class="alert alert-danger" *ngIf="inviteResponse?.error">
            {{ inviteResponse.message }}
          </div>
        </form>
 -->      </div>
    </div>
  </div>
</div>
