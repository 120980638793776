import { Component } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { ApiService, User } from '../core/api.service';

@Component({
  selector: 'register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent {
  user: User;

  // form = this.fb.group({
  //   email: [
  //     '',
  //     [
  //       Validators.required,
  //       Validators.email
  //     ]
  //   ]
  // });

  // inviteResponse;

  constructor (
    private api: ApiService,
    // private fb: FormBuilder
  ) {
    // api.getCompany('smartcompany').subscribe(company => {
    //   this.company = company.data;
    // });
  }

  ngOnInit () {
    // this.form;
  }

  register () {
    // console.log('Registering with this data:');
    // console.info(this.form.value);

    // if (this.form.valid) {
    //   this.api.registerEmail(this.form.value.email, this.company.id).subscribe(registeredEmail => {
    //     console.log(registeredEmail);
    //     this.inviteResponse = {
    //       error: false,
    //       message: 'An email has been sent with further instructions.'
    //     };
    //   }, error => {
    //     this.inviteResponse = {
    //       error: true,
    //       message: 'An unexpected error occurred.'
    //     };
    //   });
    // }
  }
}
