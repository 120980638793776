import { Component } from '@angular/core';

import { ApiService, Section, Plank, User } from './core/api.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = '2020 RPT Convention';

  // user: User;

  constructor (private api: ApiService) {
    //
  }

  ngOnInit(): void {
    // this.api.getCurrentUser().subscribe(user => {
    //   console.log(user);

    //   this.user = user;
    // });
  }
}
