<nav class="navbar navbar-expand-md navbar-light bg-light sticky-top">
  <div class="container">
    <span class="navbar-brand"><small><b>2020 RPT Convention</b></small></span>

    <button class="navbar-toggler" type="button" (click)="isMenuCollapsed = !isMenuCollapsed">
      <span class="navbar-toggler-icon"></span>
    </button>

    <div [ngbCollapse]="isMenuCollapsed" class="collapse navbar-collapse">
      <ul class="navbar-nav mr-auto">
        <!-- <li class="nav-item"><a href="#" class="nav-link">Candidates</a></li> -->
        <!-- <li class="nav-item"><a href="#" class="nav-link">Legislative Priorities</a></li> -->
        <li class="nav-item active"><a href="#" class="nav-link">Proposed Platform</a></li>
      </ul>

      <div *ngIf="user && user.id">
        <img [src]="user.photo" class="rounded-circle">
        {{ user.first_name }} {{ user.last_name }}
      </div>
      <div *ngIf="!user || !user.id">
        <a href="/api/login/facebook" class="btn btn-primary" style="background-color: #1877F2;"><img src="/assets/f_logo_white_58.png" style="width: 24px;"> Login with Facebook</a>
      </div>
    </div>
  </div>



<!--     <div class="ml-auto">
      <div *ngIf="user">
        <img [src]="user.photo" class="rounded-circle">
        Welcome, {{ user.first_name }}
      </div>
      <div *ngIf="!user">
        <a href="/api/login/facebook" class="btn btn-primary" style="background-color: #1877F2;"><img src="/assets/f_logo_white_58.png" style="width: 24px;"> Login with Facebook</a>
      </div>
    </div>
 -->

    <!--
     <div class="container">
      <div class="row">
        <div class="col-sm-9 d-xs">
          <b>2020 RPT Convention</b><br>
          Proposed RPT Platform
        </div>
        <div class="col-sm-9 d-none d-sm">
          <b>2020 RPT Convention</b> Proposed RPT Platform
        </div>
        <div class="col-sm-3">
          <div class="d-xs-block" *ngIf="!user">
            <img [src]="user.photo" class="rounded-circle">
            Welcome, {{ user.first_name }}
          </div>
          <div *ngIf="user">
            <a href="/api/login/facebook" class="btn btn-primary" style="background-color: #1877F2;"><img src="/assets/f_logo_white_58.png" style="width: 24px;"> Login with Facebook</a>
          </div>
        </div>
      </div>
    </div> -->
</nav>

<div class="container">
  <div ngbDropdown class="d-block d-md-none" #myDrop="ngbDropdown">
    <button class="btn btn-outline-primary" id="dropdownBasic1" ngbDropdownToggle>Proposed Platform</button>
    <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
      <!-- <button *ngFor="let section of sections" type="button" class="btn btn-link text-left" (click)="selectSection(section.section)">{{ section.section }}</button> -->

        <ul class="list-unstyled">
          <li class="list-unstyled" *ngFor="let section of sections">
            <button type="button" class="btn btn-link text-left" (click)="selectSection(section.section); $event.stopPropagation(); myDrop.close();">{{ section.section }}</button>
          </li>
        </ul>
    </div>
  </div>

  <hr>

  <div class="row">
    <div class="col-xs-12 col-md-4 col-lg-3 d-none d-md-block">
      <div>
        <ul class="list-unstyled">
          <!-- <li><h5 class="text-muted">Sections</h5></li> -->
          <li class="list-unstyled" *ngFor="let section of sections">
            <button type="button" class="btn btn-link text-left" (click)="selectSection(section.section)">{{ section.section }}</button>
          </li>
        </ul>
      </div>
    </div>

    <div class="col-xs-12 col-md-8 col-lg-9">
      <div>
        <div>
          <p *ngIf="selectedSection == 'Preamble'">This purpose of this tool is to provide feedback on the Proposed 2020 RPT Platform. Under each section, you will find a list of all the proposed 2020 Legislative priorities and planks.</p>
        </div>

        <h1>
          {{ selectedSection }}
        </h1>

<!--           <span class="fa-stack float-right"
            placement="left"
            ngbPopover="This purpose of this tool is to provide feedback on the 2018 RPT Platform and the proposed amendments. Under each section, you will find a list of all the 2018 planks, followed by the proposed amendments."
            popoverTitle="How does this work?">
            <i class="fas fa-circle fa-stack-2x"></i>
            <i class="fas fa-question fa-stack-1x fa-inverse"></i>
          </span>
 -->
        <div>
          <p *ngIf="selectedSection == 'Principles'">We, the 2020 Republican Party of Texas, believe in this platform and expect our elected leaders to uphold these truths through acknowledgment and action. We believe in:</p>
        </div>

        <div>
          <p *ngIf="selectedSection == 'Legislative Priorities'">The 2020 RPT Temporary Legislative Priorities Committee report contains 15 legislative priorities, followed by five additional items. Though not part of the top 15 priorities, the Committee also conveys five additional priorities that are important to many. As a Committee we referred to these as the “Report Lagniappe”.</p>
        </div>

<!--         <div class="mb-3">
          Show:
          <div class="btn-group btn-group-sm btn-group-toggle" data-toggle="buttons">
            <label class="btn btn-outline-secondary active">
              <input type="radio" name="plank_type" id="option1" autocomplete="off" checked> All
            </label>
            <label class="btn btn-outline-secondary">
              <input type="radio" name="plank_type" id="option1" autocomplete="off"> Only 2018 {{ selectedSection == 'Legislative Priorities' ? 'Legislative Priorities' : 'Planks' }}
            </label>
            <label class="btn btn-outline-secondary">
              <input type="radio" name="plank_type" id="option2" autocomplete="off"> Only Proposed Amendments
            </label>
          </div>
        </div>
 -->
        <div class="card" *ngFor="let plank of planks" style="margin-bottom: 20px" [class.border-primary]="plank.is_in_2020_platform">
          <div class="card-body">
            <div class="d-flex">
              <h5 class="card-title" style="flex-grow: 1" *ngIf="plank.is_in_2020_platform">
                {{ plank.is_2020_legislative_priority ? 'Legislative Priority' : 'Plank' }} {{ plank.plank_number_2020 }}
                <small>{{ plank.plank_short_name_2020 ? plank.plank_short_name_2020 : '' }}</small>
              </h5>
<!--               <h5 class="card-title" style="flex-grow: 1" *ngIf="!plank.is_in_2020_platform">
                Proposed Amendment <small>By SD {{ plank.submitted_by_senate_district }}, {{ plank.submitted_by_county }} County</small>
              </h5>
 -->
              <div class="text-right">
                  <span class="badge badge-pill badge-secondary" *ngIf="getVoteTotalsForPlank(plank).total > 0">{{ getVoteTotalsForPlank(plank).support_pct | percent }} of {{ getVoteTotalsForPlank(plank).total }} people agree</span><br>
                  <span class="badge badge-pill badge-secondary" *ngIf="getVoteTotalsForPlank(plank).total_include > 0">{{ getVoteTotalsForPlank(plank).support_include_pct | percent }} of {{ getVoteTotalsForPlank(plank).total_include }} people want in 2020 Platform</span><br>
                  <!-- <span class="badge badge-pill">{{ getVoteTotalsForPlank(plank).total }} Votes</span> -->
              </div>
            </div>

            <p class="card-text" style="white-space: pre-wrap">{{ plank.display_wording }}</p>
            <div>
<!--               <span class="text-success">
                <span class="fa-stack fa-xs">
                  <i class="fa fa-circle fa-stack-2x"></i>
                  <i class="fa fa-thumbs-up fa-stack-1x fa-inverse"></i>
                </span> 3
              </span>
              <span class="text-danger">
                <span class="fa-stack fa-xs">
                  <i class="fa fa-circle fa-stack-2x"></i>
                  <i class="fa fa-thumbs-down fa-stack-1x fa-inverse"></i>
                </span> 2
              </span>
 -->
            </div>
          </div>
          <div class="card-footer text-muted">
            <b>Agree:</b>
            <div class="btn-group btn-group-sm mx-2">
              <!-- add class btn-secondary when selected -->
              <button type="button" class="btn btn-small"
                (click)="voteOnPlank(plank, 'Yes')"
                [class.btn-outline-secondary]="!checkPlankVote(plank, 'Yes')"
                [class.btn-secondary]="checkPlankVote(plank, 'Yes')">
                <i class="fa fa-thumbs-up" aria-hidden="true"></i>

              </button>
              <button type="button"  class="btn btn-small"
                (click)="voteOnPlank(plank, 'No')"
                [class.btn-outline-secondary]="!checkPlankVote(plank, 'No')"
                [class.btn-secondary]="checkPlankVote(plank, 'No')">
                <i class="fa fa-thumbs-down" aria-hidden="true"></i>

              </button>
<!--               <button type="button" class="btn btn-small"
                (click)="voteOnPlank(plank, 'Unsure')"
                [class.btn-outline-secondary]="!checkPlankVote(plank, 'Unsure')"
                [class.btn-secondary]="checkPlankVote(plank, 'Unsure')">
                <i class="fa fa-question" aria-hidden="true"></i>
                Unsure / Don't Care
              </button>
 -->            </div>


            <div class="float-right">
              <b>Include in 2020 Platform:</b>
              <div class="btn-group btn-group-sm mx-2">
                <button type="button" class="btn btn-small"
                  (click)="voteOnPlankInclusion(plank, 'Yes')"
                  [class.btn-outline-secondary]="!checkPlankInclusionVote(plank, 'Yes')"
                  [class.btn-secondary]="checkPlankInclusionVote(plank, 'Yes')">
                  <i class="fa fa-check" aria-hidden="true"></i>
                  <!-- Include -->
                </button>
                <button type="button" class="btn btn-small"
                  (click)="voteOnPlankInclusion(plank, 'No')"
                  [class.btn-outline-secondary]="!checkPlankInclusionVote(plank, 'No')"
                  [class.btn-secondary]="checkPlankInclusionVote(plank, 'No')">
                  <i class="fa fa-times" aria-hidden="true"></i>
                  <!-- Don't Include -->
                </button>
              </div>
            </div>

<!--             <button type="button" (click)="viewComments()" class="btn btn-small btn-link-secondary float-right">
              <i class="fa fa-comment" aria-hidden="true"></i>
              View 3 Comments
            </button>
 -->
 <!--             <hr>
            <input class="form-control" type="text" placeholder="Default input">
  -->         </div>
          </div>

      </div>

    </div>
  </div>
</div>
