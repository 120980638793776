import { Component, OnInit } from '@angular/core';

import { ApiService, Section, Plank, PlankVote, PlankVoteTotals, User } from '../core/api.service';

@Component({
  selector: 'app-platform',
  templateUrl: './platform.component.html',
  styleUrls: ['./platform.component.scss']
})
export class PlatformComponent implements OnInit {
  isMenuCollapsed = false;

  user: User;

  selectedSection: string|null = 'Preamble';
  sections: Section[];
  planks: Plank[];

  // All the planks I've voted on
  plankVotes = new Map<number, PlankVote>();

  // All the vote totals on every plank
  plankVoteTotals = new Map<number, PlankVoteTotals>();

  // Not implemented yet
  candidateVotes = new Map();

  constructor(private api: ApiService) {
    //
  }

  ngOnInit(): void {
    this.api.getCurrentUser().subscribe(user => {
      console.log(user);

      this.user = user;
    });

    this.api.getPlatformSections().subscribe(sections => {
      console.log(sections);

      this.sections = sections;
    });

    this.api.getCurrentUserVotes().subscribe(votes => {
      if (votes.length) {
        votes.forEach(vote => {
          if (vote.plank_id != null) {
            this.plankVotes.set(vote.plank_id, vote);
          }

          if (vote.candidate_id != null) {
            this.candidateVotes.set(vote.candidate_id, vote);
          }
        });
      }
    });

    this.selectSection('Preamble');
  }

  selectSection (section) {
    this.selectedSection = section;

    this.api.getPlatformPlanksForSection(this.selectedSection).subscribe(planks => {
      this.planks = planks;
    });

    this.loadSectionVotes(this.selectedSection);

    this.isMenuCollapsed = true;
  }

  loadSectionVotes (section: string) {
    this.api.getPlankVotesForSection(section).subscribe(plankVoteTotals => {
      plankVoteTotals.forEach(plankVoteTotal => {
        // This should only return vote totals for planks, but just to be safe....
        if (plankVoteTotal.plank_id) {
          this.plankVoteTotals.set(plankVoteTotal.plank_id, plankVoteTotal);
        }
      });
    });
  }

  voteOnPlank(plank, agree) {
    // Save in case user navigates before response
    let section = this.selectedSection;

    this.api.voteOnPlank(plank.id, agree).subscribe(res => {
      this.plankVotes.set(res.plank_id, res);

      // NOTE: THIS IS SUPER INEFFICIENT WITH LOTS OF PEOPLE VOTING
      // SAVE COUNTS LOCALLY AND UPDATE OPTIMISTIC UI
      this.loadSectionVotes(section);
    });
  }

  voteOnPlankInclusion(plank, agree) {
    // Save in case user navigates before response
    let section = this.selectedSection;

    this.api.voteOnPlankInclusion(plank.id, agree).subscribe(res => {
      this.plankVotes.set(res.plank_id, res);

      // NOTE: THIS IS SUPER INEFFICIENT WITH LOTS OF PEOPLE VOTING
      // SAVE COUNTS LOCALLY AND UPDATE OPTIMISTIC UI
      this.loadSectionVotes(section);
    });
  }

  checkPlankVote (plank: Plank, agree) {
    return this.plankVotes.has(plank.id) ? this.plankVotes.get(plank.id).agree == agree : null;
  }

  checkPlankInclusionVote (plank: Plank, agree) {
    return this.plankVotes.has(plank.id) ? this.plankVotes.get(plank.id).include_in_platform == agree : null;
  }

  getVoteTotalsForPlank (plank: Plank): PlankVoteTotals {
    return this.plankVoteTotals.has(plank.id) ? this.plankVoteTotals.get(plank.id) : {
      plank_id: plank.id,
      total: 0,
      total_include: 0,
      support_pct: null,
      support_include_pct: null,
      yes: null,
      no: null,
      // unsure: null,
      yes_include: null,
      no_include: null
      // unsure_include: null,
    };
  }
}
