<div class="container">
  <div class="row">
    <div class="col-sm">
      <div class="jumbotron">
        <h1 class="display-4">404 Page Not Found</h1>
        <p class="lead">This is embarrassing... <a href="/">Go home?</a></p>
        <hr class="my-4">
      </div>
    </div>
  </div>
</div>
