import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

export interface User {
  id: number;
  name: string;
  senate_district: number;
  county: string;
  attending_2020: boolean;
}

// export interface County {
//   // id: number;
//   code: string;
//   name: string;
// }

export interface Section {
  section: string;
  section_order: number;
}

export interface Plank {
  id: number;
  display_wording: string;
  is_in_2020_platform: boolean;
  is_2020_legislative_priority: boolean;
  plank_number_2018: number;
  plank_short_name_2018: string;
  section: string;
  // submitted_by: string;
  submitted_by_county: string;
  submitted_by_senate_district: string;
}

export interface PlankVote {
  id: number;
  user_id: number;
  plank_id: number;
  candidate_id: number;
  agree: 'Yes' | 'No' | 'Unsure';
  include_in_platform: boolean | null;
  include_in_legislative_priorities: boolean | null;
}

export interface PlankVoteTotals {
  plank_id: number;
  total: number;
  total_include: number;
  support_pct: number;
  support_include_pct: number;

  yes: number;
  no: number;
  // unsure: number;
  yes_include: number;
  no_include: number;
  // unsure_include: number;
}

export interface User {
  id: number;
  first_name: string;
  last_name: string;
  email: string;
  photo: string;
  senate_district: number;
  congressional_district: number;
  country: string;
  is_attending_2020: boolean;
  facebook_id: string;
}

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  // TODO: This is for local dev, update for the real server
  baseUrl = '/api';

  constructor(
    private http: HttpClient
  ) {
    //
  }

  // User Section

  getCurrentUser (): Observable<User> {
    return this.http.get<User>(`${this.baseUrl}/currentuser/profile`);
  }

  getCurrentUserVotes (): Observable<PlankVote[]> {
    return this.http.get<PlankVote[]>(`${this.baseUrl}/currentuser/votes`);
  }

  // Plank Section

  getPlatformSections (): Observable<Section[]> {
    return this.http.get<Section[]>(`${this.baseUrl}/planks/sections`);
  }

  getPlatformPlanks (): Observable<Plank[]> {
    return this.http.get<Plank[]>(`${this.baseUrl}/planks`);
  }

  getPlatformPlanksForSection (section): Observable<Plank[]> {
    return this.http.get<Plank[]>(`${this.baseUrl}/planks/section/${section}`);
  }

  getPlankVotesForSection (section): Observable<PlankVoteTotals[]> {
    return this.http.get<PlankVoteTotals[]>(`${this.baseUrl}/planks/section/${section}/votes`);
  }

  // Vote on Plank

  voteOnPlank (plankId, agree): Observable<PlankVote> {
    return this.http.put<PlankVote>(`${this.baseUrl}/planks/${plankId}/vote`, {
      agree
    });
  }

  voteOnPlankInclusion (plankId, agree): Observable<PlankVote> {
    return this.http.put<PlankVote>(`${this.baseUrl}/planks/${plankId}/vote/include`, {
      agree
    });
  }

}
